module.exports = [{
      plugin: require('../../.yarn/cache/gatsby-plugin-smoothscroll-npm-1.2.0-d53876c6b9-6418916d3a.zip/node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-2b584af4fd/0/cache/gatsby-plugin-styled-components-npm-6.11.0-c502b4e601-47de243cc4.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"topLevelImportPaths":["@bob/library"],"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-0a758b8f9d/0/cache/gatsby-plugin-manifest-npm-5.11.0-857fd9ff94-785b9c333d.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LEGO Education","icon":"assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7ebf65ec17af49a7523ab1ac29f1c558"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../.yarn/unplugged/gatsby-virtual-615fa2f296/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
