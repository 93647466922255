import { GatsbyBrowser } from 'gatsby'
import { install } from 'resize-observer'

import { wrapPageElement as wrapPageElm } from './src/Build/wrapPageElement'

const dotCnHostname = new URL(process.env.DOT_CN_SITE_URL ?? 'https://legoeducation.cn').hostname

/**
 * TODO: this might not be needed as it only triggers on the client
 *
 * Redirect to DOT_CN_SITE_URL if new path starts with zh-cn and
 * hostname is not DOT_CN_SITE_URL hostname
 */
export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = ({ location }) => {
  if (location.hostname === 'localhost') {
    return
  }

  if (location.pathname.startsWith('/zh-cn')) {
    if (location.hostname !== dotCnHostname) {
      window.location.replace(`${process.env.DOT_CN_SITE_URL}${location.pathname}`)
    }
  } else if (location.hostname === dotCnHostname) {
    window.location.replace(`${process.env.DOT_COM_SITE_URL}${location.pathname}`)
  }
}

export const wrapPageElement = wrapPageElm

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  const installObservers = async () => {
    if (typeof IntersectionObserver === `undefined`) {
      await import('intersection-observer')
    }

    if (typeof ResizeObserver === `undefined`) {
      install()
    }
  }

  void installObservers()
}
